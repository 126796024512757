<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" max-height="300">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :disabled="disabled"
          readonly
          v-bind="attrs"
          v-on="on"
          v-model="selectedTopicName"
          label="Themenbereich"
          filled
          hide-details
        >
        </v-text-field>
      </template>
      <v-card>
        <v-treeview
          :items="topics"
          activatable
          item-key="identifier"
          item-children="sub_topics"
          @update:active="selectTopic"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const TopicsRepository = RepositoryFactory.get("topics")

import projectMixin from "@/mixins/projectMixin"

export default {
  name: "TopicSelection",
  props: ["value", "disabled"],
  mixins: [projectMixin],
  data() {
    return {
      topics: [],
      subTopics: [],
      selected: [],
      menu: false,
      selectedTopicName: "",
    }
  },
  async created() {
    const { data: topicsData } = await TopicsRepository.get()
    this.topics = topicsData

    if(this.instanceData.hasSubTopics) {
      const { data: subTopicsData } = await TopicsRepository.getSubTopics()
      this.subTopics = subTopicsData
    }
  },
  methods: {
    selectTopic(topic) {
      this.selected = topic
      if (
        this.topics.filter((topic) => topic.identifier == this.selected)
          .length > 0
      ) {
        this.selected = this.topics.filter(
          (topic) => topic.identifier == this.selected
        )[0]
        this.$emit("input", this.selected)
        this.selectedTopicName = this.selected.name
      } else {
        this.selected = this.subTopics.filter(
          (subTopic) => subTopic.identifier == this.selected
        )[0]
        this.$emit("input", this.selected)
        this.selectedTopicName = this.selected.name
      }
    },
  },
  watch: {
    value() {
      this.selected = this.value
      if (this.selected) {
        if (this.selected.topic_name) {
          this.selectedTopicName =
            this.selected.topic_name + " > " + this.selected.name
        } else {
          this.selectedTopicName = this.selected.name
        }
      } else {
        this.selectedTopicName = ""
      }
      return
    },
  },
}
</script>
