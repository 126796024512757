<template>
  <v-card class="flex flex-column my-3 mb-6">
    <v-toolbar color="primary" dark class="elevation-0">
      <v-btn icon class="mr-4">
        <v-icon>mdi-book</v-icon>
      </v-btn>

      <v-toolbar-title>
        Aktuelle Projekte
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text v-if="activeProjects.length > 1">
      <v-tabs v-model="tab" centered>
        <v-tab v-for="project in activeProjects" :key="project.id">
          {{ project.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="project in activeProjects" :key="project.year">
          <div class="text-h1 text-center">
            {{ project.name }}
          </div>
          <div>{{ project.introduction }}</div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-text v-else>
      <div class="text-h1 text-center">
        {{ activeProjects[0].name }}
      </div>
      <div>{{ activeProjects[0].introduction }}</div>
    </v-card-text>

    <WidgetPlannedArticles
      v-if="
        instanceData.hasArticlePlan &&
          articles.length > 0 &&
          this.$store.getters.isAuthor
      "
      :selectedProject="selectedProject"
      :loading="loading"
      @dialogClosed="getArticlePlannings"
    />
  </v-card>
</template>

<script>
import WidgetPlannedArticles from "@/components/dashboard/WidgetPlannedArticles"
import projectMixin from "@/mixins/projectMixin"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const ProjectsRepository = RepositoryFactory.get("projects")
const PlannedArticlesRepository = RepositoryFactory.get("plannedArticles")

export default {
  name: "ProjectOverviewWidget",
  props: {
    loading: Boolean,
  },
  mixins: [projectMixin],
  components: {
    WidgetPlannedArticles,
  },
  data() {
    return {
      activeProjects: [{ name: "", introduction: "" }],
      tab: 0,
      articles: [],
      allPlannedArticles: [],
    }
  },
  async mounted() {
    this.getActiveProjects()
    if(this.instanceData.hasArticlePlan) {
      this.getArticlePlannings()
    }
  },
  watch: {
    selectedProject: function() {
      this.articles = this.allPlannedArticles
        .filter((article) => article.project == this.selectedProject.id)
        .filter((article) => article.reused_article == null)
    },
  },
  methods: {
    async getActiveProjects() {
      const {
        data: projectsData,
      } = await ProjectsRepository.getActiveProjects()
      this.activeProjects = projectsData
    },
    async getArticlePlannings() {
      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.getByWriter(this.$store.state.user.id)
      this.allPlannedArticles = plannedArticlesData

      this.articles = this.allPlannedArticles
        .filter((article) => article.project == this.selectedProject.id)
        .filter((article) => article.reused_article == null)
    },
  },
  computed: {
    selectedProject() {
      return this.activeProjects[this.tab]
    },
  },
}
</script>

<style scoped></style>
