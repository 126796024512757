<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Meine Beiträge</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <template v-if="instanceData.hasFreeArticleSubmission">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs}">
                                <v-btn @click="newItem"
                                       v-bind="attrs"
                                       v-on="on"
                                       absolute dark fab bottom right color="primary"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Beitrag erstellen</span>
                        </v-tooltip>
    
                        <DialogArticleNew :dialog="newArticleDialog" @close="close" @saved="saved"/>
                    </template>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="tableSettings.search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :headers="headers"
                        :items="articles"
                        :search="tableSettings.search"
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, -1]}"
                        :options="tableSettings"
                        :custom-sort="customSort"
                        @update:options="updateTableSettings"
                >
                    <template v-slot:top>
                        <DialogPreviewFull
                                :dialog="preview_dialog"
                                :article_id="preview_id"
                                @close="close"
                        />
                        <DialogArticleEdit
                                :dialog="editDialog"
                                :article_id="editId"
                                @edited="edited"
                                @close="close"
                                @intermediateUpdate="updateArticle"
                        />
                    </template>

                    <template v-slot:item.id="{ item }">
                        {{ item.id }} 
                        <template v-if="item.reused_article">({{ item.reused_article }})</template>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <span class="link-indicator" @click="previewItem(item.id)">{{ item.title }}</span>
                        <span v-if="item.comment_author" class="ml-2">
                            <v-tooltip top v-if="item.comment_author">
                            <template v-slot:activator="{ on, attrs}">
                                <v-icon
                                        v-bind="attrs"
                                        v-on="on">
                                    mdi-message-text
                                </v-icon>
                            </template>
                            <span>{{ item.comment_author }}</span>
                        </v-tooltip>
                        </span>
                    </template>

                    <template v-slot:item.topic="{ item }">
                        <template v-if="instanceData.hasReadingPlan">
                            <div style="line-height: 16px">
                                <strong style="font-size: 1rem;">{{item.content_topic_name}}</strong> <br />
                                {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                            </div>
                        </template>
                        <template v-else>
                            {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                        </template>
                    </template>

                    <template v-slot:item.event_day_name="{ item }">
                        {{ item.event_day_name }}
                        <span v-if="item.event_day_topic_name && item.event_day_name"> | </span>
                        {{ item.event_day_topic_name }}
                    </template>

                    <template v-slot:item.assigned_date="{ item }">
                        <span v-if="item.assigned_date">
                            {{ item.assigned_date | luxon}}
                        </span>
                        <span v-else>-</span>
                    </template>

                    <template v-slot:item.votd_reference="{ item }">
                        {{ parseBibleRef(item.votd_reference, item.votd_reference_overwrite) }}
                    </template>

                    <template v-slot:item.writer_data="{ item }">
                        {{ item.writer_name }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    plain
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item 
                                    @click="instanceData.authorsCanEdit ? editItem(item.id) : false"
                                    :to="!instanceData.authorsCanEdit ? '/articles/' + item.id : ''"
                                    :disabled="instanceData.authorsCanEdit ? item.writer !== item.updated_user : false"
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>bearbeiten</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider/>
                                
                                <v-list-item @click="previewItem(item.id)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Vorschau</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :to="`/my-articles/${item.id}`">
                                    <v-list-item-icon>
                                        <v-icon>
                                            mdi-text-box-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Details</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogArticleNew from "@/components/articles/DialogArticleNew";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
    import DialogArticleEdit from "@/components/articles/DialogArticleEdit";
    import AppSettings from '@/config/AppSettings';

    import projectMixin from "@/mixins/projectMixin"
    import bibleRefMixin from '@/mixins/bibleRefMixin'

    const UsersRepository = RepositoryFactory.get('users');

    export default {
        name: "MyArticles",
        components: {
            MenuSetDefault,
            DialogArticleNew,
            DialogPreviewFull,
            DialogArticleEdit,
        },
        mixins: [projectMixin, bibleRefMixin],
        data() {
            return {
                AppSettings,
                userId: this.$store.state.user.id,
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Titel', value: 'title'},
                    {text: 'Arbeitstitel', value: 'topic'},
                    {text: '', value: 'topic_name', align: ' d-none'},
                    {text: '', value: 'sub_topic', align: ' d-none'},
                    {text: '', value: 'content_topic_name', align: ' d-none'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Projekt', value: 'project_name'},
                    {text: 'Anlass', value: 'event_day_name'},
                    {text: 'Anlass Thema', value: 'event_day_topic_name', align: ' d-none'},
                    {text: 'Datum', value: 'assigned_date'},
                    {text: 'Tagesvers', value: 'votd_reference'},
                    {text: 'Aktion', value: 'action', align: 'right'},
                ],
                articles: [],
                loading: true,
                preview_dialog: false,
                preview_id: null,
                editDialog: false,
                newArticleDialog: false,
                editId: null,
                tableSettings: {
                    sortBy: ['assigned_date'],
                    sortDesc: [false],
                    itemsPerPage: 25,
                    search: '',
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await UsersRepository.getArticles(this.$store.state.user.id);
                this.articles = data;
                this.loading = false;
            },
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true;
            },
            editItem(itemId) {
                this.editId = itemId;
                this.editDialog = true;
            },
            newItem() {
                this.newArticleDialog = true;
            },
            saved(item) {
                this.articles.push(item);
                this.close();
            },
            edited(editedItem) {
                const index = this.articles.findIndex( a => a.id === this.editId);
                Object.assign(this.articles[index], editedItem);
                this.close();
            },
            updateArticle(editedItem) {
                const index = this.articles.findIndex( a => a.id === this.editId);
                Object.assign(this.articles[index], editedItem);
            },
            close() {
                this.preview_dialog = false;
                this.editDialog = false;
                this.newArticleDialog = false;
                this.$nextTick(() => {
                    this.preview_id = null;
                    this.editId = null;
                })
            },
            updateTableSettings(options) {
                this.tableSettings = options;
            },
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index == "votd_reference") {
                        if (!isDesc[0]) {
                            return this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite).localeCompare(this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite));
                        } else {
                            return this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite).localeCompare(this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite));
                        }
                    } else {
                        if (!isDesc[0]) {
                            return a[index] < b[index] ? -1 : 1;
                        } else {
                            return b[index] < a[index] ? -1 : 1;
                        }
                    }
                });
                return items;
            },
            handleContentSubTopics() {
                if(!this.instanceData.hasContentTopics && !this.instanceData.hasSubTopics) {
                    this.headers[2].text = "Thema"
                }
            }
        },
        async created() {
            if (this.$route.query.openDialog === 'true') {
                this.newItem();
                await this.$router.push('/my-articles');
            }
            if (this.$store.state.user.id !== undefined) await this.fetch();
            if (this.$store.state.tableSettings[this.$route.path]) {
                this.tableSettings = this.$store.state.tableSettings[this.$route.path];
            }
            this.handleContentSubTopics()
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            next();
        },
        watch: {
            $route() {
                if (this.$route.query.openDialog === 'true') {
                    this.$router.push('/my-articles');
                    this.newItem();
                }
            },
            '$store.state.user.id'() {
                this.fetch();
            },
        },
    }
</script>
