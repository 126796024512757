import { render, staticRenderFns } from "./DialogArticleHistory.vue?vue&type=template&id=cff7d0ce&scoped=true"
import script from "./DialogArticleHistory.vue?vue&type=script&lang=js"
export * from "./DialogArticleHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff7d0ce",
  null
  
)

export default component.exports