<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Arbeitstitel erstellen</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-if="instanceData.hasContentTopics"
          label="Arbeitstitel"
          v-model="contentTopic"
          filled
          class="mb-4"
          hide-details
        />
        <TopicSelection v-model="topic" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="create" color="primary">Speichern</v-btn>
        <v-btn @click="close" text>Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import projectMixin from "@/mixins/projectMixin"

import TopicSelection from "../articles/TopicSelection"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"

const TopicsRepository = RepositoryFactory.get("topics")

export default {
  name: "DialogNewContentTopic",
  props: {
    dialog: Boolean,
  },
  components: { TopicSelection },
  mixins: [projectMixin],
  data() {
    return {
      contentTopics: [],
      contentTopic: "",
      topic: {},
    }
  },
  async mounted() {
    const {
      data: contentTopicsData,
    } = await TopicsRepository.getContentTopics()
    this.contentTopics = contentTopicsData
  },
  methods: {
    close() {
      this.resetForm()
      this.$emit("close")
    },
    async create() {
      let topicIdentifiers = this.topic.identifier.split("-")
      let newContentTopic = await TopicsRepository.addContentTopic(
        this.contentTopic,
        topicIdentifiers[0],
        topicIdentifiers[1]
      )

      this.resetForm()
      this.$emit("close", newContentTopic.data)
    },
    resetForm() {
      this.contentTopic = ""
      this.topic = {}
    },
  },
}
</script>

<style scoped></style>
